@import '../_var';
@import '../_extends';
@import '../_mixins';

// slider plugin
.n2-ss-showcase-slides.n2-ow {
	margin: 0 !important;
}
#n2-ss-2 {
	.n2-ss-showcase-slides {
		overflow: visible !important;
	}
	.nextend-arrow-reveal {
		background-color: rgba(8, 193, 113, 0.4) !important;
	}
}

